<template>
  <v-app id="inspire">
    <v-navigation-drawer
      v-model="drawer"
      mini-variant
      mini-variant-width="100px"
      app
      class="drawer"
    >
      <div class="bsat__menu-logo d-flex align-center">
        <div class="bsat__menu-logo_text">BSAT</div>
      </div>
      <template v-slot:append>
        <div
          class="d-flex flex-column justify-center align-center mb-13 text-center"
        >
          <router-link
            v-for="(item, i) in appendMenu"
            :key="i"
            :to="item.to"
            class="mb-2"
          >
            <v-icon size="32" v-text="item.icon"></v-icon>
            <p class="bsat__menu-append_text" v-text="$t(item.title)"></p>
          </router-link>
        </div>
      </template>
    </v-navigation-drawer>

    <v-app-bar fixed app flat color="#FCFFF5" class="bsat__menu">
      <v-app-bar-nav-icon
        v-if="$vuetify.breakpoint.mdAndDown"
        @click="drawer = !drawer"
      ></v-app-bar-nav-icon>
      <v-row align="center" class="px-2 px-sm-8">
        <div>
          <!-- <v-btn
            link
            text
            color="light_green"
            :ripple="false"
            @click="goTo('/')"
            >Back To Main</v-btn
          > -->
        </div>
        <v-spacer></v-spacer>
        <div>
          <v-row align="center">
            <v-btn
              width="79px"
              text
              :ripple="false"
              color="inactive_grey"
              class="mx-sm-1"
              @click.prevent="goTo('/new?phase=0')"
              :small="$vuetify.breakpoint.xsOnly"
            >
              <span :style="isNewPage ? 'color: #959c4c' : null">New </span>
            </v-btn>
            <v-btn
              text
              :ripple="false"
              color="inactive_grey"
              class="mx-sm-1"
              @click.prevent="goTo('/update?phase=0')"
              :small="$vuetify.breakpoint.xsOnly"
              ><span :style="isUpdatePage ? 'color: #959c4c' : null"
                >Update</span
              ></v-btn
            >
            <Localization />
          </v-row>
        </div>
      </v-row>
    </v-app-bar>

    <v-main>
      <slot></slot>
    </v-main>
  </v-app>
</template>

<i18n>
{
	"en": {
		"help": "Help",
		"home": "Home",
		"start": "Start"
	}
}
</i18n>

<script>
import Localization from "@/components/Localization";
export default {
  components: {
    Localization
  },
  data() {
    return {
      drawer: this.$vuetify.breakpoint.lgAndUp,
      appendMenu: [
        { title: "home", to: "/", icon: "mdi-home-outline" },
        {
          title: "Resources",
          to: "/panorama-of-resources",
          icon: "mdi-clipboard-list-outline"
        }
        // { title: "help", to: "/help", icon: "mdi-help-circle-outline" }
      ]
    };
  },
  computed: {
    isNewPage() {
      return this.$route.path === "/new";
    },
    isUpdatePage() {
      return this.$route.path === "/update";
    }
  },
  methods: {
    async goTo(path) {
      await this.$router.push(path).catch(() => {});
      this.$store.commit("INIT_STATE");
    }
  }
};
</script>

<style scoped lang="scss">
@import "@/styles/main";

.bsat__menu {
  &-logo {
    width: 100%;
    height: 134px;
    background-color: $light_green;
    &_text {
      transform: rotate(-90deg);
      color: white;
      font-family: Montserrat;
      font-size: 25px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: left;
      padding-top: 25px;
    }
  }

  &-append {
    &_text {
      font-family: $font-family;
      font-size: 10px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: $dark;
      text-decoration: none;
    }
  }

  .v-btn {
    font-family: $font-family !important;
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    text-transform: none;

    &--active {
      color: $light_green !important;
      background-color: transparent;
    }
  }

  // &__btn {
  //   &-active {
  //     color: $light_green;
  //     background-color: transparent;
  //   }
  // }
}

.v-main {
  background-color: $off_white !important;
  padding: 0px !important;
}
.drawer {
  .v-navigation-drawer__content {
    border-right: 5px solid $dark;
  }
}
</style>
